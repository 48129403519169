.logosList img {
	width: 200px;
}

.logosList .col {
	display: flex;
	justify-content: center;
	align-items: center;
}

